var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-in"},[_c('a-row',{staticClass:"row-main my-0",attrs:{"type":"flex","gutter":[24,24],"align":"middle"}},[_c('a-col',{staticClass:"col-form",attrs:{"span":24,"md":12,"lg":{span: 12, offset: 0},"xl":{span: 6, offset: 5}}},[_c('h3',{staticClass:"text-dark mb-15"},[_vm._v("Join us today")]),_c('p',{staticClass:"text-muted"},[_vm._v("Enter your email and password to register")]),_c('a-form',{staticClass:"signup-form",attrs:{"id":"components-form-demo-normal-signup","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Name","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'name',
					{ rules: [{ required: true, message: 'Please input your name!' }] } ]),expression:"[\n\t\t\t\t\t'name',\n\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your name!' }] },\n\t\t\t\t\t]"}],attrs:{"placeholder":"Name"}})],1),_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Email","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'email',
					{ rules: [{ required: true, message: 'Please input your email!' }] } ]),expression:"[\n\t\t\t\t\t'email',\n\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your email!' }] },\n\t\t\t\t\t]"}],attrs:{"placeholder":"Email"}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'password',
					{ rules: [{ required: true, message: 'Please input your password!' }] } ]),expression:"[\n\t\t\t\t\t'password',\n\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your password!' }] },\n\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Password"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'remember',
						{
							valuePropName: 'checked',
							initialValue: true,
						} ]),expression:"[\n\t\t\t\t\t\t'remember',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tvaluePropName: 'checked',\n\t\t\t\t\t\t\tinitialValue: true,\n\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}]},[_vm._v(" I agree to the "),_c('a',{staticClass:"font-bold text-dark",attrs:{"href":"#"}},[_vm._v("Terms and Conditions")])])],1),_c('a-form-item',[_c('a-button',{staticClass:"signup-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" SIGN UP ")])],1)],1),_c('p',{staticClass:"font-semibold text-muted text-center"},[_vm._v("Already have an account? "),_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":"/sign-in"}},[_vm._v("Sign Up")])],1)],1),_c('a-col',{staticClass:"col-img",attrs:{"span":24,"md":12,"lg":12,"xl":12}},[_c('div',[_c('img',{attrs:{"src":"images/img-signin.png"}})])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }